import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import DocumentModal from "../../Documents/components/DocumentModal";
import * as actions from "../_redux/categoryActions";
import { initialFilter } from "../utils/CategoryUiHelpers";
import Accordion from "../../Documents/components/Accordion";

function HeroSection({ id }) {
  const [filter, setFilter] = useState(initialFilter);
  const [catId, setCatId] = useState(undefined);
  const [documentId, setDocumentId] = useState(undefined);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchCategory(id, filter));
  }, [dispatch, filter]);

  useEffect(() => {
    if (!id) navigate("/");
  }, [id]);

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user,
    }),
    shallowEqual
  );

  const { categoryForEdit } = useSelector(
    ({ category }) => ({
      categoryForEdit: category.categoryForEdit,
    }),
    shallowEqual
  );

  const createAPost = () => {
    setDocumentId(undefined);
    setCatId(id);
    let target = document.getElementById("createAPost");
    target.click();
  };

  return (
    <>
      {/* pageheader section */}
      <button
        type="button"
        id="createAPost"
        style={{ display: "none" }}
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#bd-example-modal-lg"
      ></button>
      <DocumentModal
        id={catId}
        documentId={documentId}
        setDocumentId={setDocumentId}
      />
      <div className="bg-shape bg-dark">
        <div className="container">
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div className="pt-lg-18 pb-lg-16 py-12 ">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 col-12 mb-3">
                    {/* {/* breadcrumb */}
                    <div className="custom-breadcrumb">
                      <ol className="breadcrumb mb-2">
                        <li className="breadcrumb-item text-white">
                          <Link to={"/"}>Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active "
                          aria-current="page"
                        >
                          {categoryForEdit?.title}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <h1 className="h2 text-white mb-2">
                      {" "}
                      {categoryForEdit?.title}
                    </h1>
                    <p className="text-white-50 lead">
                      {categoryForEdit?.description}
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div>
                      <span className="position-absolute pl-3 pt-2 mt-1">
                        <i className="fe fe-search"></i>
                      </span>
                      <input
                        type="search"
                        className="form-control pl-6 mb-2 border-0"
                        placeholder="Search for articles"
                        onChange={(e) => {
                          let _filter = {
                            ...filter,
                            filter: {
                              ...filter.filter,
                              document: e.target.value,
                            },
                          };
                          setFilter(_filter);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* help center category */}
      <div className="pb-10 mt-n10">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12 col-12">
              <div className="card">
                {/* card body */}
                <div className="card-body p-4 p-lg-7">
                  <nav className="navbar-expand-md nav-simple bg-white d-flex align-items-center justify-content-between mb-3">
                    <ul
                      className="navbar-nav"
                      style={{
                        visibility: "hidden",
                      }}
                    ></ul>

                    {isAuthorized && isAuthorized.role_details.role_level <= 4 && (
                      <span
                        onClick={createAPost}
                        className="btn btn-outline-primary btn-sm d-none d-md-block"
                      >
                        New Post
                      </span>
                    )}
                  </nav>
                  {/* list */}
                  {/* <div className="accordion" id="accordionExample"> */}
                  <ul
                    className="list-group list-group-flush accordion"
                    id="accordionExample"
                  >
                    {categoryForEdit &&
                      categoryForEdit?.documents.map((ele) => (
                        <Accordion
                          key={ele.id}
                          data={ele}
                          setDocumentId={setDocumentId}
                        />
                      ))}
                  </ul>
                  {/* </div> */}
                  {/* pagination */}
                  {/* <div className="mt-4">
                    <ul className="pagination mb-0">
                      <li className="page-item mr-2 active">
                        <a className="page-link rounded" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item mr-2">
                        <a className="page-link rounded" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item mr-2">
                        <a className="page-link rounded" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link rounded" href="#">
                          4
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
