import axios from "axios";

export const APIURL = process.env.REACT_APP_API_ENDPOINT;

export const LOGIN_URL = "/api/auth/login";

export function login_with_user_module(data) {
  return axios.post(APIURL + "/api/user/login_with_user_module", data);
}

export function login_with_user_module_with_user_data(data) {
  return axios.post(
    APIURL + "/api/user/login_with_user_module_with_user_data",
    data
  );
}

export function login_using_keys(data) {
  return axios.post(APIURL + "/api/user/login_using_keys", data);
}
