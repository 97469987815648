export const initialFilter = {
  filter: {
    status: "",
    rating: "",
    category: "",
    document: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 30,
};
