import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "../../Categories/_redux/categoryActions";
import DocumentForm from "./DocumentForm";

function DocumentModal({ id, documentId, setDocumentId }) {
  const dispatch = useDispatch();
  let initDocument = {
    id: undefined,
    subject: "",
    body: "",
  };

  const handleClose = () => {
    setDocumentId(undefined);
    document.getElementById("closeDocumentModal2").click();
  };

  const saveDocument = (document, type = "create") => {
    if (id && type == "create") {
      dispatch(actions.createDocument({ ...document, categoryId: id })).then(
        (res) => {
          if (res.data && res.data.status === true) {
            handleClose();
          } else {
            let message = (res.data && res.data.message) || res.clientMessage;
            toast.warn(message);
          }
        }
      );
    } else if (documentId && type === "update") {
      // server request for updating account
      dispatch(actions.updateDocument(document)).then((res) => {
        if (res.data && res.data.status === true) {
          toast.success("document updated successfully");
          handleClose();
        } else {
          let message = (res.data && res.data.message) || res.clientMessage;
          toast.warn(message);
        }
      });
    }
  };

  const { documentForEdit } = useSelector(
    (state) => ({
      documentForEdit: state.category.documentForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting account by id
    dispatch(actions.fetchDocument(documentId));
  }, [documentId, dispatch]);

  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="bd-example-modal-lg"
        tabIndex="-1"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title h4" id="myLargeModalLabel">
                {Number(documentId) ? "Update post" : "Create post"}
              </h5>
            </div>
            <DocumentForm
              _document={documentForEdit || initDocument}
              saveDocument={saveDocument}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentModal;
