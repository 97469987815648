import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "../_redux/categoryActions";
import CategoryForm from "./CategoryForm";

function CategoryModal({ categoryId, setCategoryId }) {
  const dispatch = useDispatch();
  let initCategory = {
    id: undefined,
    title: "",
    description: "",
  };

  const handleClose = () => {
    setCategoryId(undefined);
    document.getElementById("closeCategoryModal2").click();
  };
  // server request for saving category
  const saveCategory = (category) => {
    if (!categoryId) {
      dispatch(actions.createCategory(category)).then((res) => {
        if (res.data && res.data.status === true) {
          handleClose();
        } else {
          let message = (res.data && res.data.message) || res.clientMessage;
          toast.warn(message);
        }
      });
    } else {
      // server request for updating category
      dispatch(actions.updateCategory(category)).then((res) => {
        if (res.data && res.data.status === true) {
          handleClose();
        } else {
          let message = (res.data && res.data.message) || res.clientMessage;
          toast.warn(message);
        }
      });
    }
  };
  const { categoryForEdit } = useSelector(
    (state) => ({
      categoryForEdit: state.category.categoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting account by id
    dispatch(actions.fetchCategory(categoryId));
  }, [categoryId, dispatch]);

  return (
    <>
      <div
        className="modal fade"
        tabIndex="-1"
        id="exampleModal"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title h4" id="exampleModalLabel">
                Create Category
              </h5>
              {/* <button
                type="button"
                id="closeCategoryModal"
                style={{ display: "none" }}
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <CategoryForm
              category={categoryForEdit || initCategory}
              saveCategory={saveCategory}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryModal;
