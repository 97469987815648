import React from "react";

function Cta() {
  return (
    <>
      <div className="row">
        {/* cta */}
        <div className="col-lg-6 col-md-6 col-12">
          <div className="card rounded-lg mb-5 text-center">
            <div className="card-body p-6">
              <i className="fe fe-help-circle font-42 text-primary mb-5 d-block"></i>
              <h3>How to subscribe</h3>
              <p>
                Learn about our subscription plans and how to subscribe to
                programs from Blackforce Inc.
              </p>

              <a
                href="https://salespage.blackforce.ca/"
                className="btn btn-secondary"
              >
                Subscribe Now
              </a>
            </div>
          </div>
        </div>
        {/* cta */}
        <div className="col-lg-6 col-md-6 col-12">
          <div className="card rounded-lg mb-5 text-center">
            <div className="card-body p-6">
              <i className="fe fe-help-circle font-42 text-primary mb-5 d-block"></i>
              <h3>Enrollment Instructions</h3>
              <p>
                Get help with step by step instructions on how to enroll to
                programs from Blackforce Inc.
              </p>

              <a
                href="https://www.blackforce.ca/enrolment"
                className="btn btn-secondary"
              >
                View instructions
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* cta */}
        <div className="col-lg-6 col-md-6 col-12">
          <div className="card rounded-lg mb-5 text-center">
            <div className="card-body p-6">
              <i className="fe fe-help-circle font-42 text-primary mb-5 d-block"></i>
              <h3>New Programs</h3>
              <p>
                Learn about the latest courses and programs from Blackforce Inc.
              </p>

              <a
                href="https://blackforce.ca/programs"
                className="btn btn-secondary"
              >
                View Programs
              </a>
            </div>
          </div>
        </div>
        {/* cta */}
        <div className="col-lg-6 col-md-6 col-12">
          <div className="card rounded-lg mb-5 text-center ">
            <div className="card-body p-6">
              <i className="fe fe-life-buoy font-42 text-primary mb-5 d-block"></i>
              <h3>Support</h3>
              <p>
                If you dont find your question solution just click and fill the
                form for support.
              </p>

              <a
                href="https://blackforce.ca/support"
                className="btn btn-secondary"
              >
                Contact Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cta;
