import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../../../pages/components/Footer";
import Nav from "../../../pages/components/Nav";
import HeroSection from "./HeroSection";

function ViewCategoryPage() {
  let { id } = useParams();
  return (
    <>
      <Nav />
      <HeroSection id={id} />
      <Footer />
    </>
  );
}

export default ViewCategoryPage;
