import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import CategoryModal from "../../modules/Categories/components/CategoryModal";

function HomeHeader({ categoryId, setCategoryId, setFilter, filter }) {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user,
    }),
    shallowEqual
  );

  const createAPost = () => {
    // let target = document.getElementById("createAPost");
    // target.click();
  };

  const createACategory = () => {
    setCategoryId(undefined);
    let target = document.getElementById("createACategory");
    target.click();
  };
  return (
    <>
      <button
        type="button"
        id="createACategory"
        style={{ display: "none" }}
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
      ></button>
      {/* <button
        type="button"
        id="createAPost"
        style={{ display: "none" }}
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#bd-example-modal-lg"
      ></button> */}
      <CategoryModal categoryId={categoryId} setCategoryId={setCategoryId} />
      <div className="bg-shape bg-dark">
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
              <div className="py-lg-20 py-14 ">
                <h1 className="text-white text-center mb-5">
                  Hi, Need some help with Blackforce?
                </h1>
                <div className="px-lg-13 px-4">
                  <span className="position-absolute pl-3 pt-2 mt-1">
                    <i className="fe fe-search"></i>
                  </span>
                  <input
                    type="search"
                    onChange={(e) => {
                      let _filter = {
                        ...filter,
                        filter: {
                          ...filter.filter,
                          category: e.target.value,
                        },
                      };
                      setFilter(_filter);
                    }}
                    className="form-control pl-6 mb-2 border-0"
                    placeholder="Search I can't login"
                  />
                  <span className="text-white text-center mt-3 d-block">
                    ... or choose a category to quickly find the help you need
                  </span>
                </div>
                {isAuthorized && isAuthorized.role_details.role_level <= 4 && (
                  <div className="text-center mt-5">
                    {/* <span
                      onClick={createAPost}
                      className="btn btn-primary mr-5"
                    >
                      New Post
                    </span> */}

                    <span onClick={createACategory} className="btn btn-primary">
                      New Category
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeHeader;
