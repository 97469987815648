import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Input } from "../../../../_singleLogin/_partials/controls/forms/Input";
import { TextArea } from "../../../../_singleLogin/_partials/controls/forms/TextArea";

const API_URL = process.env.REACT_APP_API_ENDPOINT;
const UPLOAD_ENDPOINT = "upload";

// Validation schema
const DocumentEditSchema = Yup.object().shape({
  subject: Yup.string()
    .min(20, "Minimum 20 characters")
    .max(100, "Maximum 100 characters")
    .required("This field is required"),
  body: Yup.string().required("This field is required"),
});

function DocumentForm({ _document, saveDocument }) {
  const [editorDefaultData, setEditorDefaultData] = useState("<br />");
  const btnRef = useRef();
  const saveDocumentClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
  };

  //   const inputHandler = (event, editor) => {
  //     formik.setFieldValue("signator_text", editor.getData());
  // };
  useEffect(() => {
    if (_document.id && _document.id !== undefined) {
      setEditorDefaultData(_document.body);
    }
    return function cleanup() {
      setEditorDefaultData("<br />");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_document.id]);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.url,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      {" "}
      <Formik
        enableReinitialize={true}
        initialValues={_document}
        validationSchema={DocumentEditSchema}
        onSubmit={(values) => {
          if (values.id && values.id !== undefined) {
            saveDocument(values, "update");
          } else {
            saveDocument(values);
          }
          // console.log(values);
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          values,
          resetForm,
        }) => (
          <>
            <div className="modal-body">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="subject"
                      component={Input}
                      placeholder="Subject"
                      label="Post Subject"
                    />
                  </div>
                  <div className="col-lg-12 mt-4">
                    <CKEditor
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      editor={ClassicEditor}
                      data={editorDefaultData}
                      // onReady={(editor) => {
                      //   // You can store the "editor" and use when it is needed.
                      //   console.log("Editor is ready to use!", editor);
                      // }}
                      onChange={(event, editor) => {
                        setFieldValue("body", editor.getData());
                      }}
                    />
                    <ErrorMessage name="body" />
                  </div>
                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={() => handleSubmit()}
                ></button>
              </Form>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                id="closeDocumentModal2"
                onClick={(e) => {
                  e.preventDefault();
                  // setEditorDefaultData("<br />");
                  resetForm();
                  document.querySelector("#closeDocumentModal").click();
                }}
              >
                Close
              </button>
              <button
                type="button"
                id="closeDocumentModal"
                style={{ display: "none" }}
                className="btn btn-secondary"
                data-dismiss="modal"
              ></button>
              <button
                type="button"
                onClick={saveDocumentClick}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}

export default DocumentForm;
