import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Nav() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user,
    }),
    shallowEqual
  );

  return (
    <>
      <div className="header fixed-top">
        {/* navigation start */}
        <div className="container-lg">
          <nav className="navbar navbar-expand-lg navbar-default">
            <a className="navbar-brand" href="https://blackforce.ca/programs">
              <img
                src="https://blackforce.ca/assets/images/logo-white.png"
                alt=""
                width={100}
              />
            </a>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar mt-0"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-default">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-default"
                aria-controls="navbar-default"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fas fa-times"></i>
              </button>
              <ul className="navbar-nav ml-auto mr-lg-3 ">
                <li className="nav-item dropdown disabled">
                  <a className="nav-link d-lg-none" href="#">
                    Menu
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/"}>
                    {" "}
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://blackforce.ca/programs">
                    {" "}
                    Programs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://blackforce.ca/about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://blackforce.ca/support">
                    Support
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://blackforce.ca/contact">
                    {" "}
                    Contact Us
                  </a>
                </li>
              </ul>
              {isAuthorized ? (
                <Link to="/logout" className="btn btn-primary m-3 m-lg-0  ">
                  Sign out
                </Link>
              ) : (
                <Link to="/auth/login" className="btn btn-primary m-3 m-lg-0  ">
                  Sign in
                </Link>
              )}
            </div>
          </nav>
        </div>
      </div>

      {/* pageheader section */}
    </>
  );
}

export default Nav;
