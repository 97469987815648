import axios from "axios";

export const CATEGORY_URL = process.env.REACT_APP_API_ENDPOINT + "/category";

// READ
export function getAllSetting() {
  return axios.get(CATEGORY_URL);
}
// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSetting(queryParams) {
  return axios.get(`${CATEGORY_URL}`);
}

export function getCategoryById(categoryId, filter) {
  return axios.get(
    `${CATEGORY_URL}/${categoryId}?query=${JSON.stringify(filter)}`
  );
}

export function getDocumentById(id) {
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/document/${id}`);
}

export function createCategory(category) {
  return axios.post(CATEGORY_URL, { category });
}
export function createDocument(document) {
  return axios.post(process.env.REACT_APP_API_ENDPOINT + "/document", {
    document,
  });
}

export function updateCategory(category) {
  return axios.put(`${CATEGORY_URL}/${category.id}`, { category });
}

export function updateDocument(document) {
  return axios.put(
    `${process.env.REACT_APP_API_ENDPOINT}/document/${document.id}`,
    { document }
  );
}

export function fetchCategories(queryParams) {
  return axios.get(`${CATEGORY_URL}?query=${JSON.stringify(queryParams)}`);
}

export function deleteCategory(accountId) {
  return axios.delete(`${CATEGORY_URL}/${accountId}`);
}

export function deleteDocument(id) {
  return axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/document/${id}`);
}
