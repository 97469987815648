import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./components/Nav";
import Footer from "./components/Footer";
import Cta from "./components/Cta";
import { initialFilter } from "../modules/Categories/utils/CategoryUiHelpers";

import * as actions from "../modules/Categories/_redux/categoryActions";
import CategoryCard from "./components/CategoryCard";
import HomeHeader from "./components/HomeHeader";

export default function LandingPage() {
  const [categoryId, setCategoryId] = useState(undefined);
  const [filter, setFilter] = useState(initialFilter);
  let queries = queryString.parse(window.location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (queries.ret == "1") {
      toast.warn("Your session has expired. Please login");
    }
  }, []);
  useEffect(() => {
    dispatch(actions.fetchCategories(filter));
  }, [dispatch, filter]);

  const { entities } = useSelector(
    ({ category }) => ({
      entities: category.entities,
    }),
    shallowEqual
  );
  return (
    <>
      <ToastContainer />
      {/* {/* help center */}
      <Navbar />
      <HomeHeader
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        setFilter={setFilter}
        filter={filter}
      />
      <div className="pb-10 mt-n13">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12 col-12">
              <div className="row">
                {entities &&
                  entities.map((ele) => (
                    <CategoryCard
                      key={ele.id}
                      data={ele}
                      categoryId={categoryId}
                      setCategoryId={setCategoryId}
                    />
                  ))}
              </div>
              <Cta />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
