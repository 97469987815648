import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import * as authCrud from "../_redux/authCrud";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  public_key: "",
  private_key: "",
  module_id: "",
};

function Login(props) {
  const dispatch = useDispatch();
  let { public_key, private_key, module_id } = useParams();
  // let { status, token, refresh_token, user } = useParams();

  // const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    public_key: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required Field"),
    private_key: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required Field"),
    module_id: Yup.string()
      .min(3, "Minimum 3 symbols")
      .required("Required Field"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setLoading(true);
      login_using_keys(values.public_key, values.private_key, values.module_id);
    },
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const login_with_user_module = async (tokentemp, module_id) => {
    try {
      setLoading(true);
      const response = await authCrud.login_with_user_module({
        token: tokentemp,
        module_id: module_id,
      });
      setLoading(false);
      if (response.data.status) {
        //we have token in this response
        let authToken = response.data.token;
        let refreshToken = response.data.refresh_token;
        let user = response.data.user;

        //save this token

        dispatch(auth.actions.login(authToken, refreshToken, user));
        // dispatch(auth.actions.fulfillUser(user));
      } else {
        formik.setStatus(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      formik.setStatus(
        typeof error === "string"
          ? error
          : error.response
          ? error.response.data.message
          : "Unable to login!!! Try again"
      );
    }
  };

  const login_using_keys = async (public_key, private_key, module_id) => {
    try {
      //we need to send this data to backend

      let map = {
        public_key,
        private_key,
        module_id,
      };

      setLoading(true);
      const res = await authCrud.login_using_keys(map);
      const response = res.data;
      setLoading(false);
      if (response.status) {
        //we have token in this response
        let authToken = response.token;
        let refreshToken = response.refresh_token;
        let user = response.user;

        dispatch(auth.actions.login(authToken, refreshToken, user));
        // dispatch(auth.actions.fulfillUser(user));
        // loadToState(authToken, refreshToken, user);
        //save this token
      } else {
        formik.setStatus(response.message);
      }
    } catch (error) {
      setLoading(false);
      formik.setStatus(
        typeof error === "string"
          ? error
          : error.response
          ? error.response.data.message
          : "Unable to login!!! Try again"
      );
    }
  };

  // const loadToState = (authToken, refreshToken, user) => {
  //   dispatch(auth.actions.login(authToken, refreshToken, user));
  // };
  // useEffect(() => {
  //   if (status === "1" && token && refresh_token && user) {
  //     loadToState(
  //       decodeURIComponent(token),
  //       decodeURIComponent(refresh_token),
  //       JSON.parse(decodeURIComponent(user))
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [status, token, refresh_token, user]);

  useEffect(() => {
    if (public_key && private_key && module_id) {
      login_using_keys(public_key, private_key, module_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [public_key, private_key, module_id]);
  useEffect(() => {
    window.addEventListener(
      "message",
      async (event) => {
        let url = process.env.REACT_APP_USER_MODULE_URL;
        url = url.split("/");

        var protocol = url[0];
        var host = url[2];
        var origin = protocol + "//" + host;
        if (event.origin === origin) {
          //window.location.href = "<%= redirectURL %>/moduleauthenticated?token=" + event.data.token + '&module_id=' + event.data.module_id

          //we need to send this token to API, and check that is this a new user or a previous user
          //if this is a new user then we need to ask them for their role i.e. Teacher or student
          login_with_user_module(event.data.token, event.data.module_id);
        }
        return;
      },
      false
    );
    return;
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="min-vh-100 d-flex align-items-center "
      style={{
        background: `url(/assets/images/register-img.jpg)no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6 col-12">
            <Link to="/" className="mb-4 d-flex justify-content-center">
              <img
                src="/assets/images/logo-white.png"
                alt=""
                width="150px"
                style={{ marginTop: "-10px" }}
              />
            </Link>

            <div className="bg-dark p-4 p-lg-8 rounded-lg">
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                <h1 className="mb-2 text-white">Welcome</h1>
                <p className="mb-4">
                  Sign in using your SingleLogin.io credentials.
                </p>
                {formik.status ? (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="mb-3">
                  <label className="form-label text-white-50">
                    Public Key{" "}
                  </label>
                  <input
                    placeholder=""
                    type="text"
                    id="public_key"
                    className={`form-control border-0 ${getInputClasses(
                      "public_key"
                    )}`}
                    name="public_key"
                    {...formik.getFieldProps("public_key")}
                  />
                  {/* <input type="email" id="email" className="form-control border-0" placeholder="Email address" required="" /> */}
                </div>
                <div className="mb-3">
                  <label className="form-label text-white-50">
                    Private Key{" "}
                  </label>
                  <input
                    placeholder=""
                    type="text"
                    id="private_key"
                    className={`form-control border-0 ${getInputClasses(
                      "private_key"
                    )}`}
                    name="private_key"
                    {...formik.getFieldProps("private_key")}
                  />
                  {/* <input type="email" id="email" className="form-control border-0" placeholder="Email address" required="" /> */}
                </div>
                <div className="mb-3 mb-4">
                  <label className="form-label  text-white-50">Module ID</label>
                  <input
                    placeholder=""
                    type="text"
                    id="module_id"
                    className={`form-control border-0 ${getInputClasses(
                      "module_id"
                    )}`}
                    name="module_id"
                    {...formik.getFieldProps("module_id")}
                  />
                  {/* <input type="password" id="password" className="form-control border-0" placeholder="Password" required="" /> */}
                </div>

                <div className="text-center">
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    Sign in
                    {loading && (
                      <div
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
                <div className="d-lg-flex justify-content-between mt-4 mb-3 ">
                  <div style={{ margin: "auto" }}>OR</div>
                </div>
              </form>
              <div className="text-center">
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    setLoading(true);
                    window.open(
                      process.env.REACT_APP_USER_MODULE_URL,
                      "test",
                      "width=500,height=500"
                    );
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Sign in with SingleLogin.io"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(Login);
