import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  categoryForEdit: undefined,
  documentForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    categoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.categoryForEdit = action.payload.categoryForEdit;
      state.error = null;
    },
    documentFetched: (state, action) => {
      state.actionsLoading = false;
      state.documentForEdit = action.payload.documentForEdit;
      state.error = null;
    },
    categoryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.category);
    },
    categoryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    documentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categoryForEdit.documents =
        state.categoryForEdit?.documents?.filter(
          (el) => el.id !== action.payload.id
        );
    },
    categorysFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      // if (r === true) toast.success("Acknowledged!...");
    },
    categoryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.category.id) {
          return action.payload.category;
        }
        return entity;
      });
    },
    documentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categoryForEdit.documents = state.categoryForEdit?.documents?.map(
        (entity) => {
          if (entity.id === action.payload.document.id) {
            return action.payload.document;
          }
          return entity;
        }
      );
      // state.documentForEdit = action.payload.document;
    },
  },
});
