import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "../../../pages/components/Nav";
import * as actions from "../../Categories/_redux/categoryActions";
import Footer from "../../../pages/components/Footer";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DocumentModal from "./DocumentModal";

function ViewDocumentPage() {
  const [documentId, setDocumentId] = useState(undefined);
  let { id } = useParams();
  let navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchDocument(id));
  }, [dispatch]);

  useEffect(() => {
    if (!id) navigate("/");
  }, [id]);

  const { documentForEdit } = useSelector(
    ({ category }) => ({
      documentForEdit: category.documentForEdit,
    }),
    shallowEqual
  );

  const updateADocument = () => {
    let target = document.getElementById("createAPost");
    target.click();
  };

  const deleteADocument = () => {
    Swal.fire({
      title: `Delete this post?`,
      text: "This action cannot be reversed",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(actions.deleteDocument(id)).then((res) => {
          if (res.data && res.data.status === true) {
            toast.success("Post deleted successfully");
            navigate(`/category/${documentForEdit?.categoryId}`);
          } else {
            let message = (res.data && res.data.message) || res.clientMessage;
            toast.warn(message);
          }
        });
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
    // let target = document.getElementById("createACategory");
    // target.click();
  };
  return (
    <>
      {" "}
      <Nav />
      <button
        type="button"
        id="createAPost"
        style={{ display: "none" }}
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#bd-example-modal-lg"
      ></button>
      <DocumentModal id={undefined} documentId={id} />
      <div className="bg-shape bg-dark">
        <div className="container">
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-12">
              <div className="pt-lg-18 pb-lg-16 py-12 ">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 col-12 mb-3">
                    {/* {/* breadcrumb */}
                    <div className="custom-breadcrumb">
                      <ol className="breadcrumb mb-2">
                        <li className="breadcrumb-item text-white">
                          <Link to={"/"}>Home</Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          <Link to={`/category/${documentForEdit?.categoryId}`}>
                            {documentForEdit?.categoryName}
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item active "
                          aria-current="page"
                        >
                          {documentForEdit?.subject}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <h1 className="h2 text-white mb-2">
                      {" "}
                      {documentForEdit?.categoryName}
                    </h1>
                    <p className="text-white-50 lead">
                      {documentForEdit?.categoryDescription}
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    {/* <form>
                      <span className="position-absolute pl-3 pt-2 mt-1">
                        <i className="fe fe-search"></i>
                      </span>
                      <input
                        type="search"
                        className="form-control pl-6 mb-2 border-0"
                        placeholder=" "
                      />
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* body section */}
      <div className="pb-10 mt-n10">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12 col-12">
              {/* card */}
              <div className="card rounded-lg mb-4">
                {/* card body */}
                <div className="card-body p-4 p-lg-7">
                  <div className="d-flex justify-content-between">
                    <h3 className="mb-4">{documentForEdit?.subject}</h3>
                    <div>
                      <span className="dropdown">
                        <span
                          className="text-muted text-decoration-none"
                          role="button"
                          id="dropdownOne"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-vertical"></i>
                        </span>

                        <span
                          className="dropdown-menu dropdown-menu-right dropdown-menu-md-left"
                          aria-labelledby="dropdownOne"
                        >
                          <span
                            className="dropdown-item"
                            role="button"
                            onClick={updateADocument}
                          >
                            Edit
                          </span>
                          <span
                            className="dropdown-item text-danger"
                            role="button"
                            onClick={deleteADocument}
                          >
                            Delete
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* <h3 className="mb-4">{documentForEdit?.subject}</h3> */}
                  <div className="d-flex mb-4 align-items-md-center">
                    <img
                      src="../assets/images/user-photo.png"
                      alt=""
                      className="avatar-lg rounded-circle"
                    />
                    <div className="ml-3 lh-1">
                      <h5 className="mb-1">
                        <a
                          href="help-center-profile-member.html"
                          className="text-inherit"
                        >
                          {documentForEdit?.authorName}
                        </a>
                      </h5>
                      <small>
                        <span>@ {documentForEdit?.userName}</span>
                        <span className="ml-2">
                          Updated {moment(documentForEdit?.updatedAt).fromNow()}
                        </span>
                      </small>
                    </div>
                  </div>
                  {/* document body */}
                  <div
                    dangerouslySetInnerHTML={{ __html: documentForEdit?.body }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ViewDocumentPage;
