import React, { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_singleLogin/_partials/controls/forms/Input";
import { TextArea } from "../../../../_singleLogin/_partials/controls/forms/TextArea";

// Validation schema
const CategoryEditSchema = Yup.object().shape({
  title: Yup.string()
    .min(10, "Minimum 10 characters")
    .max(30, "Maximum 30 characters")
    .required("This field is required"),
  description: Yup.string()
    .min(65, "Minimum 65 characters")
    .max(100, "Maximum 100 characters")
    .required("This field is required"),
});

function CategoryForm({ category, saveCategory }) {
  const btnRef = useRef();
  const saveCategoryClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {" "}
      <Formik
        enableReinitialize={true}
        initialValues={category}
        validationSchema={CategoryEditSchema}
        onSubmit={(values) => {
          saveCategory(values);
          //   console.log(values);
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          values,
          resetForm,
        }) => (
          <>
            <div className="modal-body">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="title"
                      component={Input}
                      placeholder="Title"
                      label="Category Title"
                    />
                  </div>
                  <div className="col-lg-12 mt-4">
                    <Field
                      name="description"
                      component={TextArea}
                      placeholder="Description"
                      label="Category Description"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={btnRef}
                  onSubmit={() => handleSubmit()}
                ></button>
              </Form>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                id="closeCategoryModal2"
                onClick={(e) => {
                  e.preventDefault();
                  resetForm();
                  document.querySelector("#closeCategoryModal").click();
                }}
              >
                Close
              </button>
              <button
                type="button"
                id="closeCategoryModal"
                style={{ display: "none" }}
                className="btn btn-secondary"
                data-dismiss="modal"
              ></button>
              <button
                type="button"
                onClick={saveCategoryClick}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}

export default CategoryForm;
