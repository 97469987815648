import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as actions from "../../modules/Categories/_redux/categoryActions";

function CategoryCard({ data, categoryId, setCategoryId }) {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const updateACategory = () => {
    setCategoryId(data.id);
    let target = document.getElementById("createACategory");
    target.click();
  };

  const deleteACategory = () => {
    Swal.fire({
      title: `Delete "${data?.title}"?`,
      text: "You will loose all articles in this category",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(actions.deleteCategory(data.id)).then((res) => {
          if (res.data && res.data.status === true) {
            toast.warn("Category deleted successfully");
          } else {
            let message = (res.data && res.data.message) || res.clientMessage;
            toast.warn(message);
          }
        });
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <div className="col-lg-6 col-md-6 col-12">
      {/* card */}
      <div
        className="card rounded-lg mb-5"
        // style={{
        //   minHeight: "270px",
        // }}
      >
        <div className="card-body p-5">
          <div className="d-flex justify-content-between">
            <span className="mb-5 d-block">
              <i className="fe fe-book text-primary font-42"></i>
            </span>
            <div>
              {isAuthorized && isAuthorized.role_details.role_level <= 4 && (
                <span className="dropdown">
                  <span
                    className="text-muted text-decoration-none"
                    role="button"
                    id="dropdownOne"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fe fe-more-vertical"></i>
                  </span>

                  <span
                    className="dropdown-menu dropdown-menu-right dropdown-menu-md-left"
                    aria-labelledby="dropdownOne"
                  >
                    <span
                      className="dropdown-item"
                      role="button"
                      onClick={updateACategory}
                    >
                      Edit
                    </span>
                    <span
                      className="dropdown-item text-danger"
                      role="button"
                      onClick={deleteACategory}
                    >
                      Delete
                    </span>
                  </span>
                </span>
              )}
            </div>
          </div>
          {/* <nav className="navbar-expand-md nav-simple bg-white d-flex align-items-center justify-content-between mb-3">
         
            <ul className="navbar-nav mt-n8">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarTags"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  :
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarTags"
                >
                  <a className="dropdown-item" href="#">
                    Admin
                  </a>
                </div>
              </li>
            </ul>
          </nav> */}

          <h3 className="mb-2">
            <Link to={`/category/${data.id}`} className="text-inherit">
              {data?.title}
            </Link>
          </h3>
          <p className="mb-0">{data?.description}</p>
        </div>
      </div>
    </div>
  );
}

export default CategoryCard;
