import React from "react";

export default function Footer() {
  return (
    <>
      {/* <!-- footer --> */}
      <div className="footer pt-11 pb-3 bg-dark text-base">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-6">
              <div className="mb-4">
                <h4 className="mb-4 text-white">About</h4>
                <ul className="list-unstyled list-group">
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Meet Coach
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Press
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Social Feed
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      {" "}
                      Help
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="mb-4">
                <h4 className="mb-4 text-white ">Blog</h4>
                <ul className="list-unstyled list-group ">
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Business Coaching
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Relationship
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Leadership
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Life Coaching
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Time Management
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="mb-4">
                <h4 className="mb-4 text-white ">Resources</h4>
                <ul className="list-unstyled list-group ">
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Online Courses
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      {" "}
                      Books Meditation
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      {" "}
                      Podcast
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Albums
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Download App
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="mb-4">
                <h4 className="mb-4 text-white ">Follow Me</h4>
                <ul className="list-unstyled list-group">
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Instagram
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      {" "}
                      Facebook
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      {" "}
                      LinkedIn
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      YouTube
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="#" className="list-group-item-link">
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-12">
              <div className="mb-4">
                <h4 className="mb-4 text-white">Subscribe to our newsletter</h4>
                <div>
                  <p>
                    Subscribe to get notified daily new motivational &
                    inspiration tips.
                  </p>
                  <form>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control border-white"
                        placeholder="Email Address"
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mt-8">
                <ul className="list-inline">
                  <li className="list-inline-item">© 2022 Blackforce Inc.</li>
                  <li className="list-inline-item">
                    <a href="#" className="text-reset">
                      {" "}
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="text-reset">
                      {" "}
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
