import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as actions from "../../Categories/_redux/categoryActions";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DocumentModal from "./DocumentModal";

function Accordion({ data, setDocumentId }) {
  let { id } = data;

  const dispatch = useDispatch();

  const updateADocument = () => {
    setDocumentId(data.id);
    let target = document.getElementById("createAPost");
    target.click();
  };

  const deleteADocument = () => {
    Swal.fire({
      title: `Delete this post?`,
      text: "This action cannot be reversed",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(actions.deleteDocument(id)).then((res) => {
          if (res.data && res.data.status === true) {
            toast.success("Post deleted successfully");
            // navigate(`/category/${data?.categoryId}`);
          } else {
            let message = (res.data && res.data.message) || res.clientMessage;
            toast.warn(message);
          }
        });
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
    // let target = document.getElementById("createACategory");
    // target.click();
  };
  return (
    <li className="list-group-item px-0 py-3 card">
      <h5
        style={{
          cursor: "pointer",
        }}
      >
        <span
          // className="card-header"
          className="mb-0 "
          id={`headingOne${data?.id}`}
          data-toggle="collapse"
          data-target={`#collapseOne${data?.id}`}
          aria-expanded="false"
          aria-controls={`collapseOne${data?.id}`}
        >
          {data?.subject}
        </span>
        {/* </h2> */}
      </h5>
      <span className="font-12">{moment(data?.createdAt).fromNow()}</span>

      <div
        id={`collapseOne${data?.id}`}
        className="collapse"
        aria-labelledby={`headingOne${data?.id}`}
        data-parent="#accordionExample"
      >
        <div className="card-body">
          {/* body section */}
          {/* <div className="pb-10 mt-n10"> */}
          {/* <div className="container"> */}
          {/* <div className="row"> */}
          {/* <div className=" col-12"> */}
          {/* card */}
          {/* <div className="rounded-lg mb-4"> */}
          {/* card body */}
          {/* <div className="p-4 p-lg-7"> */}
          <div className="d-flex justify-content-between">
            {/* <h3 className="mb-4">{data?.subject}</h3> */}
            <div className="d-flex mb-4 align-items-md-center">
              <img
                src="../assets/images/user-photo.png"
                alt=""
                className="avatar-lg rounded-circle"
              />
              <div className="ml-3 lh-1">
                <h5 className="mb-1">
                  <span className="text-inherit">{data?.authorName}</span>
                </h5>
                <small>
                  <span>@ {data?.userName}</span>
                  <span className="ml-2">
                    Updated {moment(data?.updatedAt).fromNow()}
                  </span>
                </small>
              </div>
            </div>
            <div>
              <span className="dropdown">
                <span
                  className="text-muted text-decoration-none"
                  role="button"
                  id="dropdownOne"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fe fe-more-vertical"></i>
                </span>

                <span
                  className="dropdown-menu dropdown-menu-right dropdown-menu-md-left"
                  aria-labelledby="dropdownOne"
                >
                  <span
                    className="dropdown-item"
                    role="button"
                    onClick={updateADocument}
                  >
                    Edit
                  </span>
                  <span
                    className="dropdown-item text-danger"
                    role="button"
                    onClick={deleteADocument}
                  >
                    Delete
                  </span>
                </span>
              </span>
            </div>
          </div>
          {/* document body */}
          <div
            dangerouslySetInnerHTML={{
              __html: data?.body,
            }}
          />
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </li>
  );
}

export default Accordion;
