import { initialFilter } from "../utils/CategoryUiHelpers";
import * as requestFromServer from "./categoryCrud";
import { categorySlice, callTypes } from "./categorySlice";

const { actions } = categorySlice;
export const fetchCategory = (id, filter) => (dispatch) => {
  if (!id) {
    return dispatch(actions.categoryFetched({ categoryForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCategoryById(id, filter)
    .then((response) => {
      const category = response.data.data;
      dispatch(actions.categoryFetched({ categoryForEdit: category }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCategory = (categoryForCreation) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createCategory(
      categoryForCreation
    );
    if (response.data.status) {
      const category = response.data.data;
      dispatch(actions.categoryCreated({ category }));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't create category";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const createDocument = (document) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createDocument(document);
    if (response.data.status) {
      dispatch(fetchCategory(document.categoryId, initialFilter));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't create document";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const updateDocument = (document) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.updateDocument(document);
    if (response.data.status) {
      dispatch(actions.documentUpdated({ document }));
      // let queryParams = initialFilter;
      // dispatch(fetchCategories(queryParams));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't update document";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};
export const updateCategory = (category) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.updateCategory(category);
    if (response.data.status) {
      dispatch(actions.categoryUpdated({ category }));
      // let queryParams = initialFilter;
      // dispatch(fetchCategories(queryParams));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't update category";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const fetchCategories = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchCategories(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data.data;
      dispatch(
        actions.categorysFetched({
          totalCount,
          entities,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find categorys";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const deleteCategory = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteCategory(id);
    dispatch(actions.categoryDeleted({ id }));
  } catch (error) {
    error.clientMessage = "Can't delete category";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const deleteDocument = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteDocument(id);
    if (response.data.status) {
      dispatch(actions.documentDeleted({ id }));
    }
    // dispatch(actions.documentDeleted({ id }));
    return response;
  } catch (error) {
    error.clientMessage = "Can't delete document";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const fetchDocument = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.documentFetched({ documentForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDocumentById(id)
    .then((response) => {
      const document = response.data.data;
      dispatch(actions.documentFetched({ documentForEdit: document }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find document";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
