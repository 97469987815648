import React from "react";
import ReactDOM from "react-dom/client";
import { SplashScreenProvider } from "./components/SplashScreen";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "react-toastify/dist/ReactToastify.css";
// import "./components/assets/libs/ion-rangeslider/css/ion.rangeSlider.min.css";
// import "./components/assets/css/theme.min.css";

const { PUBLIC_URL } = process.env;

_redux.setupAxios(axios, store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <SplashScreenProvider>
    <ToastContainer />
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </SplashScreenProvider>
  // </React.StrictMode>
);
