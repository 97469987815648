import React, { Suspense, lazy, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { LayoutSplashScreen } from "../components/SplashScreen";
import Login from "./modules/Auth/pages/Login";
import Logout from "./modules/Auth/pages/Logout";
import LandingPage from "./pages/LandingPage";
import ErrorPage from "./pages/components/ErrorPage";
import ViewCategoryPage from "./modules/Categories/components/ViewCategoryPage";
import ViewDocumentPage from "./modules/Documents/components/ViewDocumentPage";

export function AppRoutes() {
  let navigate = useNavigate();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized) {
      return navigate("/");
    }
  }, [isAuthorized]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        <Route
          path="/auth/login/:public_key/:private_key/:module_id"
          element={<Login />}
        />
        <Route path="/" element={<LandingPage />} />
        <Route path="/category/:id" element={<ViewCategoryPage />} />
        <Route path="/document/:id" element={<ViewDocumentPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Routes>
    </Suspense>
  );
}
// }
